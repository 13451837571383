import { popoverAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { ThemeColors } from './ThemeColors'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(popoverAnatomy.keys)

const baseStyle = definePartsStyle({
	content: {
		_light: {
			backgroundColor: ThemeColors.WHITE,
		},
		_dark: {
			backgroundColor: ThemeColors.GRAY_950,
		},
	},
})

export const popoverTheme = defineMultiStyleConfig({ baseStyle })
