import { modalAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { ThemeColors } from 'components/chakraComponents/ThemeColors'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(modalAnatomy.keys)

const baseStyle = definePartsStyle({
	dialog: {
		_dark: {
			backgroundColor: ThemeColors.GRAY_950,
		},
		_light: {
			backgroundColor: ThemeColors.WHITE,
		},
	},
})

export const modalTheme = defineMultiStyleConfig({ baseStyle })
