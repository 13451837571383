import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { ThemeColors } from 'components/chakraComponents/ThemeColors'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys)

const baseStyle = definePartsStyle({
	track: {
		_dark: {
			backgroundColor: ThemeColors.GRAY_800,
			_checked: {
				backgroundColor: ThemeColors.WHITE,
			},
		},
		_light: {
			backgroundColor: ThemeColors.GRAY_200,
			_checked: {
				backgroundColor: ThemeColors.GRAY_900,
			},
		},
	},
	thumb: {
		_dark: {
			backgroundColor: ThemeColors.GRAY_200,
			_checked: {
				backgroundColor: ThemeColors.GRAY_950,
			},
		},
	},
})

export const switchTheme = defineMultiStyleConfig({ baseStyle })
