import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { ThemeColors } from 'components/chakraComponents/ThemeColors'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
	field: {
		_light: {
			_focusVisible: {
				borderColor: ThemeColors.GRAY_800,
				borderWidth: '1.5px',
				boxShadow: 'none',
			},
		},
		_dark: {
			_focusVisible: {
				borderColor: ThemeColors.GRAY_200,
				borderWidth: '1.5px',
				boxShadow: 'none',
			},
		},
	},
})

export const inputTheme = defineMultiStyleConfig({ baseStyle })
