import MdmSocketEvents from 'enums/mdmSocketEvents'
import { useAuth } from 'oidc-react'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { io } from 'socket.io-client'

const ClientType = 0
const AppType = 4
const releaseName = 'devrelease'

const URL = `${
	process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
		? process.env.REACT_APP_SIGNALING_URL
		: window.__env__.REACT_APP_SIGNALING_URL
}/mdm`

const IO = io(URL, {
	secure: true,
	transports: ['websocket'],
	autoConnect: false,
})

const SocketContext = createContext(null)

export const useSocketContext = () => useContext(SocketContext)

const SocketContextProvider = ({ children }) => {
	const { userData } = useAuth()
	const accessTokenRef = useRef(null)
	const socketListenersOffRef = useRef([])

	const authorizeCallback = () => {
		if (!userData?.access_token || userData?.access_token === accessTokenRef.current) {
			return
		}

		accessTokenRef.current = userData?.access_token
		const clientInfo = {
			token: userData?.access_token,
			clearConferences: false,
			clientType: ClientType,
			appType: AppType,
			versionName: releaseName,
			oldSocketId: null,
			incomingCallsDisabled: false,
		}

		socket.emit(MdmSocketEvents.Client.AUTHORIZE, clientInfo, () => {})
	}

	const connect = () => {
		if (!userData) {
			return
		}

		if (IO.connected && accessTokenRef.current !== userData?.access_token) {
			authorizeCallback()
			return
		}

		initSocketListeners()

		IO.connect()
	}

	const disconnect = () => {
		if (!IO.connected) {
			return
		}

		IO.disconnect()
		accessTokenRef.current = null
		socketListenersOffRef.current.forEach((off) => off())
	}

	const initSocketListeners = () => {
		const bindEventListener = (event, listener) => {
			socket.on(event, listener)
			socketListenersOffRef.current.push(() => socket.off(event, listener))
		}

		bindEventListener(MdmSocketEvents.Client.ON_CONNECT, () => {
			if (!userData?.access_token) {
				return
			}

			authorizeCallback()
		})

		bindEventListener(MdmSocketEvents.Client.ON_DISCONNECT, (reason) => {
			if (reason === 'io server disconnect') {
				socket.connect()
			}
		})
	}

	useEffect(() => {
		if (IO.connected && userData?.access_token !== accessTokenRef.current) {
			authorizeCallback()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData?.access_token])

	useEffect(() => {
		connect()
		// Disable reason: useEffect should be invoked once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [socket] = useState(() =>
		Object.assign(IO, {
			doConnect: connect,
			doDisconnect: disconnect,
		})
	)

	return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
}

export default SocketContextProvider
