const MdmSocketEvents = {
	Client: {
		AUTHORIZE: 'client.authorize',
		ON_CONNECT: 'connect',
		ON_DISCONNECT: 'disconnect',
	},
	Device: {
		STATUS_ONLINE: 'client.deviceOnline',
		STATUS_OFFLINE: 'client.deviceOffline',
		LOCATION_CHANGED: 'mdm.device.location.changed',
	},
}

export default MdmSocketEvents
