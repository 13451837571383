export const ThemeColors = {
	GRAY_950: '#111111',
	WHITE: '#FFFFFF',
	BLACK_ALPHA900: 'blackAlpha.800',
	LIGHT_GRAY: '#34343433',
	LIGHTER_GRAY: '#FFFFFF0d',
	GRAY_800: '#27272a',
	GRAY_200: '#e4e4e7',
	GRAY_900: '#18181b',
}
