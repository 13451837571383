import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
	container: {
		rounded: 'xl',
		_light: {
			color: 'blackAlpha.800',
		},
		_dark: {
			backgroundColor: 'inherit',
			color: 'whiteAlpha.800',
		},
	},
})

export const cardTheme = defineMultiStyleConfig({ baseStyle, defaultProps: { variant: 'outline' } })
