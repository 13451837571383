import { userRoles } from 'enums'
import {
	AndroidIconOutline,
	AppAddIconOutline,
	AppReleaseIconOutline,
	AppsIconOutlined,
	DevicesIconOutline,
	DiagnosticsIcon,
	FlavorIconOutline,
	FloorIconOutline,
	IconCompanyOutlined,
	IconHospitalOutlined,
	ProfileIconOutline,
	ReleaseIconOutline,
	SideBarDashboardIcon,
	SideBarDashboardLightIcon,
	SideBarDeviceManagementIcon,
	SideBarDeviceManagementLightIcon,
	SideBarOTAIcon,
	SideBarOTALightIcon,
	SideBarProvisioningIcon,
	SideBarProvisioningLightIcon,
	SideBarUserManagementIcon,
	SideBarUserManagementLightIcon,
	UserIconOutline,
} from 'icons/global'

export const newTabs = [
	{
		label: 'Dashboard',
		icon: ({ isDarkMode, isActive }) =>
			isDarkMode ? <SideBarDashboardIcon isActive={isActive} /> : <SideBarDashboardLightIcon isActive={isActive} />,
		path: '/',
		isParent: true,
		roles: [userRoles.SUPER_USER, userRoles.SUPPORT, userRoles.DEVELOPER],
	},
	{
		label: 'User Management',
		icon: ({ isDarkMode, isActive }) =>
			isDarkMode ? <SideBarUserManagementIcon isActive={isActive} /> : <SideBarUserManagementLightIcon isActive={isActive} />,
		isParent: true,
		children: [
			{ label: 'Users', icon: UserIconOutline, path: 'users', roles: [userRoles.SUPER_USER] },
			{
				label: 'Companies',
				icon: IconCompanyOutlined,
				path: 'companies',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
			{
				label: 'Hospitals',
				icon: IconHospitalOutlined,
				path: 'hospitals',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
		],
		roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
	},
	{
		label: 'Provisioning',
		icon: ({ isDarkMode, isActive }) =>
			isDarkMode ? <SideBarProvisioningIcon isActive={isActive} /> : <SideBarProvisioningLightIcon isActive={isActive} />,
		isParent: true,
		children: [
			{
				label: 'Devices',
				icon: DevicesIconOutline,
				path: 'devices',
				roles: [userRoles.SUPER_USER, userRoles.SUPPORT, userRoles.DEVELOPER],
			},
			{ label: 'Profiles', icon: ProfileIconOutline, path: 'profiles', roles: [userRoles.SUPER_USER, userRoles.DEVELOPER] },
			{
				label: 'Hospital Floors',
				icon: FloorIconOutline,
				path: 'hospital-floors',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
		],
		roles: [userRoles.SUPER_USER, userRoles.DEVELOPER, userRoles.SUPPORT],
	},
	{
		label: 'OTA Deployment',
		icon: ({ isDarkMode, isActive }) =>
			isDarkMode ? <SideBarOTAIcon isActive={isActive} /> : <SideBarOTALightIcon isActive={isActive} />,
		isParent: true,
		children: [
			{
				label: 'OS Deployments',
				icon: AndroidIconOutline,
				path: 'os-deployments',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
			{
				label: 'APP Deployments',
				icon: AppAddIconOutline,
				path: 'app-deployments',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
		],
		roles: [userRoles.SUPER_USER, userRoles.DEVELOPER, userRoles.SUPPORT],
	},
	{
		label: 'Device Management',
		icon: ({ isDarkMode, isActive }) =>
			isDarkMode ? <SideBarDeviceManagementIcon isActive={isActive} /> : <SideBarDeviceManagementLightIcon isActive={isActive} />,
		isParent: true,
		children: [
			{
				label: 'Device Diagnostics',
				icon: DiagnosticsIcon,
				path: 'device-diagnostics',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
			{
				label: 'Device Flavors',
				icon: FlavorIconOutline,
				path: 'device-flavors',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
			{ label: 'Applications', icon: AppsIconOutlined, path: 'device-apps', roles: [userRoles.SUPER_USER, userRoles.DEVELOPER] },
			{ label: 'OS Releases', icon: ReleaseIconOutline, path: 'os-releases', roles: [userRoles.SUPER_USER, userRoles.DEVELOPER] },
			{
				label: 'APP Releases',
				icon: AppReleaseIconOutline,
				path: 'app-releases',
				roles: [userRoles.SUPER_USER, userRoles.DEVELOPER],
			},
		],
		roles: [userRoles.SUPER_USER, userRoles.DEVELOPER, userRoles.SUPPORT],
	},
]
