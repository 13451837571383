import { Input, InputGroup, InputLeftElement, useDisclosure } from '@chakra-ui/react'
import { GlobalSearchModal } from 'components'
import { SearchIcon } from 'icons/global'

const GlobalSearch = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<InputGroup onClick={onOpen} size='sm'>
				<Input cursor='pointer' placeholder='Search' isReadOnly rounded='full' />
				<InputLeftElement>
					<SearchIcon />
				</InputLeftElement>
			</InputGroup>
			{isOpen && <GlobalSearchModal isOpen={isOpen} onClose={onClose} />}
		</>
	)
}

export default GlobalSearch
