import { Flex, Spinner } from '@chakra-ui/react'
import { setAccessToken } from 'helpers'
import { useAuth } from 'oidc-react'
import AppRoutes from 'routes'
import SocketContextProvider from 'contexts/SocketContext'

const signinOidcPath = '/signin-oidc'

const locationPath = window.location.pathname

const App = () => {
	const { userData } = useAuth()

	if (!userData && locationPath !== signinOidcPath) {
		localStorage.setItem('redirectPath', locationPath)
	}

	if (userData) {
		setAccessToken(userData.access_token)
		return (
			<SocketContextProvider>
				<AppRoutes />
			</SocketContextProvider>
		)
	}

	return (
		<Flex h='100vh' alignItems='center' justifyContent='center'>
			<Spinner size='lg' />
		</Flex>
	)
}

export default App
