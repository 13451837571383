import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { ThemeColors } from 'enums'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys)

const baseStyle = definePartsStyle({
	thead: {
		_light: {
			backgroundColor: ThemeColors.LIGHT_MODE.WHITE,
		},
		_dark: {
			backgroundColor: ThemeColors.LIGHT_MODE.BLACK,
		},
	},
	th: {
		_dark: {
			borderColor: ThemeColors.LIGHT_MODE.LIGHT_GRAY,
		},
	},
	td: {
		_dark: {
			borderColor: ThemeColors.LIGHT_MODE.LIGHT_GRAY,
		},
	},
})

export const tableTheme = defineMultiStyleConfig({ baseStyle, defaultProps: { size: 'sm' } })
